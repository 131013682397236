(function($, window, document) {
    var lightSolutionsUrl = 'data/api/v1/light-solutions';
    var calculationUrl = 'data/api/v1/calculate';
    var selectedLightSolution = 1;

    var taggingInitialized = false;

    if (location.href.indexOf('://localhost') > -1 || location.href.indexOf('ngrok.io') > -1) {
      lightSolutionsUrl = 'https://phi-laas-acc.jelastic.trimm.net/data/api/v1/light-solutions';
      calculationUrl = 'https://phi-laas-acc.jelastic.trimm.net/data/api/v1/calculate';
    }

    $(function() {
      $(window).on("laas:initLightingSolutions", initLightingSolutionsEventHandler);

      $('.laa-card__input-placeholder--initial').on('click', placeholderClickHandler);
      $('.laa-card__input').on('focus', inputFocusHandler);
      $('.laa-card__input').on('blur', inputBlurHandler);
      $('.laa-card__input').on('change', inputChangeHandler);
      $('.laa-card__input').on('keyup', inputKeyUpHandler);

      $('.laa-explanation-toggle--open').on('click', moreInfoHandler);
      $('.laa-explanation-toggle--close').on('click', closeHandler);

      $('.laa-card__lighting-solution').on('change', lightingSolutionChangeHandler);
      $('.laa-card__input-row__submit-container').on('click', submitClickHandler);

      $('.laa-slider__control--back').on('click', sliderBackClickHandler);
      $('.laa-slider__control--forward').on('click', sliderForwardClickHandler);
      $('.laa-slider__control--close').on('click', sliderCloseClickHandler);

      $('.laa-card__mail-bar').on('click', mailBarClickHandler);
      $('.laa-mail__back').on('click', mailBackClickHandler);

      onlyNumbersAllowed();
    });

    var init = false;

    function initLightingSolutionsEventHandler(event, translations) {
        if (init) return;
        init = true;
        initLightingSolutions(translations);
    }

    function initLightingSolutions(translations) {

      var $select = $('.laa-card__lighting-solution');

      $.get(lightSolutionsUrl, function(data, textStatus, jqXHR) {
        for (var i = 0; i < data.data.length; i++) {
          var lookup = data.data[i].name,
              name = (translations[lookup] !== undefined) ? translations[lookup] : lookup;

          $select.append('<option value="' + data.data[i].id + '">' + name + '</option>');
        }

        $select.append('<option value="-1">' + translations['what_is_it'] + '</option>');

        $('.laa-card__lighting-solution').selectric();
        $select.trigger("change");
        calculate();
      });
    }

    function lightingSolutionChangeHandler() {
      var $select = $('.laa-card__lighting-solution');

      // console.log(taggingInitialized);

      // if (taggingInitialized) {
        switch ($select.val()) {
          case '1':
            philips.analytics.trackConversion ({
                name:"interaction",
                description:"line_lighting:option"
            });
            break;

          case '2':
            philips.analytics.trackConversion ({
                name:"interaction",
                description:"in_building_sites:option"
            });
            break;

          case '3':
            philips.analytics.trackConversion ({
                name:"interaction",
                description:"combination_line_and_spots:option"
            });
            break;

          case '-1':
            $('body').addClass('laa--wiw-visible');

            flipCard(true);
            $select.val(selectedLightSolution);
            $select.selectric('refresh');
            $select.trigger('change');

            $('.selectric').click();
            break;
          }
        // }

      taggingInitialized = true;

      if ($select.val() != -1) {
        selectedLightSolution = $select.val();
      }
    }

    function lightingSolutionOpenHandler() {
      setTimeout(function() {
        $('.select2-results__option').addClass('needsclick');
      }, 100);

      checkInput();
    }

    function inputChangeHandler() {
      checkInput();
    }

    function inputKeyUpHandler() {
      checkInput();
    }

    function checkInput() {
      var ready = true;

      if ($('[name="surface"]').val().length == 0) {
        ready = false;
      }
      if ($('[name="stores"]').val().length == 0) {
        ready = false;
      }
      if ($('[name="hours"]').val().length == 0) {
        ready = false;
      }
      if ($('.laa-card__lighting-solution').val().length == 0) {
        ready = false;
      }

      $('.laa-card').toggleClass('laa-card--calculatable', ready);
      $('.laa-card__input-row__submit-container').toggleClass('laa--disabled', !ready);
      $('.laa-card__submit').prop('disabled', !ready);
    }

    function placeholderClickHandler() {
      var $this = $(this);
      var $container = $this.closest('.laa-card__input-row__input-container');

      focusInputContainer($container);
    }

    function inputFocusHandler() {
      var $this = $(this);
      var $container = $this.closest('.laa-card__input-row__input-container');

      focusInputContainer($container);
    }

    function inputBlurHandler() {
      var $this = $(this);
      var $container = $this.closest('.laa-card__input-row__input-container');

      blurInputContainer($container);
    }

    function focusInputContainer($container) {
      var $input = $container.find('input');

      $container.addClass('laa--has-focus');

      if (!$input.is(':focus')) {
        $input.focus();
      }
    }

    function blurInputContainer($container) {
      var $input = $container.find('input');

      if ($input.val().length == 0) {
        $container.removeClass('laa--has-focus');
      }
    }

    function moreInfoHandler() {
      flipCard();

      philips.analytics.trackConversion ({
        name:"interaction",
        description:"more_information"
      });
    }

    function closeHandler() {


      unflipCard();
    }

    function sliderBackClickHandler() {
      var $slider = $('.laa-slider');

      if ($slider.hasClass('laa--second')) {
        $slider.removeClass('laa--second').addClass('laa--first');
      }
      else if ($slider.hasClass('laa--third')) {
        $slider.removeClass('laa--third').addClass('laa--second');
      }
    }

    function sliderCloseClickHandler() {
      closeHandler();

      window.setTimeout(function(){
          $('.laa-slider').removeClass('laa--third').addClass('laa--first');
      }, 1000);
    }

    function sliderForwardClickHandler() {
        var $slider = $('.laa-slider');

        if ($slider.hasClass('laa--first')) {
            $slider.removeClass('laa--first').addClass('laa--second');
        }
        else if ($slider.hasClass('laa--second')) {
            $slider.removeClass('laa--second').addClass('laa--third');
        }
    }



    function flipCard(showHelp) {
      var $card = $('.laa-card');

        $('body').removeClass('laa--transition--fade');


        if ($card.hasClass('laa-card--unflipped')) {
        $card.toggleClass('laa-card--flipped').toggleClass('laa-card--unflipped');
      }
      else {
        $card.addClass('laa-card--flipped');
      }

      $card.toggleClass('laa-card--help', showHelp == true);
      $('body').toggleClass('laa--formula-visible', showHelp != true);
    }

    function unflipCard() {
      var $card = $('.laa-card');

      $card.toggleClass('laa-card--flipped').toggleClass('laa-card--unflipped');
      $('body').removeClass('laa--formula-visible');
      $('body').removeClass('laa--wiw-visible');


      if ($card.hasClass('laa-card--help')) {
        setTimeout(function() {
          $card.removeClass('laa-card--help');
          $('.selectric').click();
        }, 500);
      }
    }

    function onlyNumbersAllowed() {
      $('.laa-card__input').keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
          // Allow: Ctrl+A, Command+A
          (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right, down, up
          (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }
      });
    }

    function reset() {
      $('[name="surface"]').val('').blur();
      $('[name="stores"]').val('').blur();
      $('[name="hours"]').val('').blur();

      calculate();
      checkInput();
    }

    function mailBarClickHandler() {
        $('body').addClass('laa--email-visible');
        $('body').removeClass('laa--transition--fade');
    }

    function mailBackClickHandler() {
        $('body').addClass('laa--transition--fade');
        $('body').removeClass('laa--email-visible');
    }

    function submitClickHandler() {
      calculate(true);
    }

    function calculate(sendAnalytics) {
      var data = {
        'squareMeters': $('[name="surface"]').val(),
        'numberOfStores': $('[name="stores"]').val(),
        'openHoursPerYear': $('[name="hours"]').val(),
        'lightSolution': {
          'id' : $('.laa-card__lighting-solution').val(),
        },
        'locale': null
      };

      $.ajax({
        url: calculationUrl,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        success: function(data, textStatus, jqXHR) {
          if (data.status == 'success') {

            var $resultRow = $('.laa-card__calc-row--result');
            var operationalCosts = Math.round(data.data.operationalCosts);
            var savings = Math.round(data.data.savings);
            var laasTariff = Math.round(data.data.laasTariff);
            var newOperationalCosts = operationalCosts - savings + laasTariff;
            var profit = operationalCosts - newOperationalCosts;

            var paramMaintenance = data.data.parameters.o;
            var paramStores = data.data.parameters.stores;
            var paramSurface = data.data.parameters.m2;
            var paramHours = data.data.parameters.h;
            var paramPrice = data.data.parameters.p;
            var paramSavings = data.data.parameters.b;
            var paramLaasTariff = data.data.parameters.lt;

            Cookies.set('serverData', data.data);
            Cookies.set('newOperationalCosts', newOperationalCosts);

            $('.laa-card__header__savings__amount').html(Math.abs(profit).toLocaleString('it-IT'));
            $('.laa-card__calc-row__value__amount--current-costs').html(operationalCosts.toLocaleString('it-IT'));
            $('.laa-card__calc-row__value__amount--savings').html(savings.toLocaleString('it-IT'));
            $('.laa-card__calc-row__value__amount--laas-tariff').html(laasTariff.toLocaleString('it-IT'));
            $('.laa-card__calc-row__value__amount--new-costs').html(newOperationalCosts.toLocaleString('it-IT'));
            $('.laa-card__calc-row__value__amount--profit').html(Math.abs(profit).toLocaleString('it-IT'));

            $('.laa-card').toggleClass('laa-card--negative', profit < 0);
            $resultRow.removeClass('laa-card__calc-row--positive');
            $resultRow.removeClass('laa-card__calc-row--negative');
            if (profit < 0) {
              $resultRow.addClass('laa-card__calc-row--negative');
            }

            if (profit > 0) {
              $resultRow.addClass('laa-card__calc-row--positive');
            }

            if (sendAnalytics == true) {
              var analyticsDescriptionArray = [];

              analyticsDescriptionArray.push('values:m2_' + paramSurface);
              analyticsDescriptionArray.push(':uren_' + paramHours);
              analyticsDescriptionArray.push(':winkels_' + paramStores);
              analyticsDescriptionArray.push(':finalvalue_' + profit.toLocaleString('it-IT'));

              philips.analytics.trackConversion({
                name: 'interaction',
                description: analyticsDescriptionArray.join('')
              });
            }
          }
        }
      });
    }

}(window.jQuery, window, document));

